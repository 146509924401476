import React from "react";
import styles from "./style.scss";
import cn from "classnames";
import { connect } from "react-redux";
import {
  setBinderActionValue,
  resetBinderActionValue,
  setBinderOpenFlgValue
} from "modules/binders/list/actions";
import DefinedButton from "components/Common/Button/Defined";
import TextButton from "components/Common/Button/Text";
import toast from "components/Common/Toast/enhance";
import modalUtil from "components/Common/Modal/enhance";
import { extractNumberFromVariables } from "utils/helper/format";
import variable from "utils/css/_variables.scss";
import LoadingSpinner from "components/Common/LoadingSpinner";
import { validateKeywords } from "features/FormulaSearch/handlers";
import halfKanaMap from "utils/json/halfKanaMap";
import fullKanaMap from "utils/json/fullKanaMap.json";
import { trackAction } from "utils/helper/analytics";
import { convertSearchKeyword } from "utils/helper/searchTag";
import OverseasPremiumModal from "features/OverseasPremium/DetailModal";
import { BinderActionModal } from "features/BinderPage/Common/BinderActionModal";
import { cloneDeep } from "lodash";
import { setSeletedCorpItems } from "modules/binders/item/actions";

/**
 * ブックマーク表示
 *
 * @param {object} list - ブックマーク情報
 * @param {boolean} isLoading - リストローディング中
 * @param {?string} color - ボタンの色
 * @param {?string} checkedNkcodes チェックした企業リスト
 * @param {?string} selectedIndustryCode チェックした業界リスト
 * @param {?string} moveBookmarkIds チェックした移動するブックマークIDリスト
 * @param {?function} onMoveBookmark ブックマーク移動 callback
 * @param {?string} hideFolderId フォルダ一覧に表示しないID
 * @param {?string} size ボタンの表示サイズ
 * @param {?function} onOpenBalloon コレクション追加ボタン押下 callback
 * @param {?function} onOpenCreateNewForm コレクションを新規作成押下 callback
 */
const defaultProps = {
  color: "primary",
  disabled: false,
  checkedNkcodes: "",
  selectedNkCorps: [],
  selectedIndustryCode: "",
  moveBookmarkIds: "",
  onMoveBookmark: () => {},
  hideFolderId: null,
  size: "middle",
  onOpenBalloon: () => {},
  onOpenCreateNewForm: () => {},
  onClickIcon: () => {},
  onSave: () => {}
};

const initialState = {
  isOpen: false,
  keyword: "",
  error: {
    caption: "",
    description: "",
    other: [],
    definition: "",
    background: "",
    task: "",
    technology: "",
    topics: "",
    regulation: ""
  },
  binder: {
    caption: "",
    description: "",
    andKeyword: "",
    orKeyword: "",
    notKeyword: "",
    query: "",
    setShare: false,
    includeRelatedCorp: true,
    mediaList: [],
    searchType: "0",
    isOpenCollectionModal: false
  },
  complements: {
    definition: [{ value: "" }],
    background: [{ value: "" }],
    task: [{ value: "" }],
    technology: [{ title: "", value: "" }],
    topics: [{ title: "", value: "" }],
    regulation: [{ title: "", value: "" }]
  },
  isOpenEditComplement: false,
  isOpenSearchHelp: false,
  isSaveLoading: false,
  isOpenConfirmModal: false,
  isOpenDetailModal: false
};

class BookMarkBalloon extends React.Component {
  constructor(props) {
    super(props);
    this.state = cloneDeep(initialState);
    this.dispatch = this.props.dispatch;
    this.wrapperRef = React.createRef();
    this.clickHandler = this.clickHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onClose = this.onClose.bind(this);
    this.createBinderClickHandler = this.createBinderClickHandler.bind(this);
    this.setDefaultFeedKeywords = this.setDefaultFeedKeywords.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.trackMediaCheck = this.trackMediaCheck.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setValidateError = this.setValidateError.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClose);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClose);
  }

  componentDidUpdate(prevProps) {
    if (this.props.openFlg !== prevProps.openFlg) {
      if (this.props.openFlg === false) {
        this.onClose();
      }
    }
  }

  // ブックマークボタン押下時
  async clickHandler(event) {
    event.stopPropagation();
    if (this.props.disabled) return;

    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
    await this.props.getBinderList();
    this.inputRef.current && this.inputRef.current.focus();

    trackAction("collectionAddtion");
  }

  // エリア外押下時
  handleClose(e) {
    const { binderAction } = this.props;
    if (!this.wrapperRef.current.contains(e.target) && !binderAction) {
      this.dispatch(resetBinderActionValue());
      this.dispatch(setBinderOpenFlgValue(false));
      this.setState(cloneDeep(initialState));
    }
  }

  onClose() {
    this.dispatch(resetBinderActionValue());
    this.dispatch(setBinderOpenFlgValue(false));
    this.setState(cloneDeep(initialState));
  }

  shouldDisplayUpperPart() {
    if (this.calculateBalloonDisplayPosition() === "upperpart") {
      return true;
    } else {
      return false;
    }
  }

  calculateBalloonDisplayPosition() {
    if (this.wrapperRef == null) return;

    // ヘッダーの高さ
    const headerHeight = extractNumberFromVariables(variable.lHeaderHeight);

    const screenSizeY = window.innerHeight - headerHeight;
    const rect = this.wrapperRef.current.getBoundingClientRect();

    // コレクションモーダルを表示させる範囲が決まっているときの処理
    if (this.props.displayRange && this.props.displayRange.y) {
      if (this.props.displayRange.y[0] > rect.top - 274) return "";
      if (this.props.displayRange.y[1] < rect.top + 274) return "upperpart";
    }

    if (screenSizeY / 2 < rect.top) {
      return "upperpart";
    } else {
      return;
    }
  }

  createBinderClickHandler() {
    this.dispatch(setBinderActionValue({ type: "new" }));
    this.setState({
      ...this.state,
      binder: {
        ...this.state.binder,
        isOpenCollectionModal: true
      }
    });
    this.dispatch(setBinderOpenFlgValue(true));
    this.dispatch(setSeletedCorpItems(this.props.selectedNkCorps));
    trackAction("collectionAddtionCreate");
  }

  setDefaultFeedKeywords() {
    if (!this.props.defaultFeedKeywords) return;
    this.setState(prev => ({
      ...prev,
      binder: {
        ...prev.binder,
        query: convertSearchKeyword(this.props.defaultFeedKeywords)
      }
    }));
  }

  async handleSave(params) {
    if (!params.caption) params["caption"] = "名称未設定";
    this.setState(prev => ({ ...prev, isSaveLoading: true }));
    if (
      !params.andKeyword &&
      !params.orKeyword &&
      !params.notKeyword &&
      !params.query
    ) {
      delete params.andKeyword;
      delete params.orKeyword;
      delete params.notKeyword;
      delete params.query;
    } else {
      const validatedKeywords = validateKeywords(
        params.query,
        {
          and: params.andKeyword ? params.andKeyword : "",
          or: params.orKeyword ? params.orKeyword : "",
          not: params.notKeyword ? params.notKeyword : ""
        },
        this.props.isAdvancedMode
      );
      params.andKeyword = validatedKeywords.andKeyword;
      params.orKeyword = validatedKeywords.orKeyword;
      params.notKeyword = validatedKeywords.notKeyword;
      params.query = validatedKeywords.rawText;
    }
    try {
      const response = await this.props.insertItem(params);
      if (response.value && response.value.result === "OK") {
        this.props.toggleBinderListRefetchFlg();
      } else if (response.body && response.body.details) {
        const errors = response.body.details.map(detail => detail.message);
        this.setValidateError(errors);
      }
      trackAction("myCollectionSave", {
        binderId: response.value.binderId,
        type: "コレクション作成完了"
      });
    } catch (error) {
      const errors = error.body.details.map(detail => detail.message);
      this.setValidateError(errors);
    } finally {
      this.setState(prev => ({ ...prev, isSaveLoading: false }));
    }
  }

  async getMediaList() {
    if (this.state.binder.mediaList.length) return;
    await this.props.getMediaList();
    this.setState(prev => ({
      binder: {
        ...prev.binder,
        mediaList: this.props.mediaList.map(item => {
          if (item.baseOptFlg === "9") {
            return {
              ...item,
              checked: false
            };
          }
          return { ...item };
        })
      }
    }));
  }

  renderButton() {
    if (this.props.children) {
      return (
        <TextButton
          className={styles.textButton}
          disabled={this.props.disabled}
          onClick={this.clickHandler}
          color={this.props.color}
          data-testid="Common-BookmarkBalloon-textButton"
        >
          {this.props.children}
        </TextButton>
      );
    }

    if (this.props.size === "icon") {
      return (
        <span
          data-testid={`Common-BookmarkBalloon-iconButton-${
            this.props.disabled ? "disabled" : "enabled"
          }`}
          className={cn(styles.addBinderIcon, {
            [styles.disabled]: this.props.disabled
          })}
          onClick={this.clickHandler}
        />
      );
    }

    return (
      <DefinedButton
        type="bookmark"
        disabled={this.props.disabled}
        onClick={this.clickHandler}
        size={this.props.size}
        data-testid="Common-BookmarkBalloon-definedButton"
      />
    );
  }

  onFocus() {
    // テキストフィールドフォーカス中はモーダル閉じるキーイベントを解除
    this.props.removeKeyEventListener();
  }

  onBlur() {
    // モーダル閉じるキーイベントを貼り直す
    this.props.addKeyEventListener();
  }

  toHalfSize(str) {
    // 全角英数字 → 半角英数字
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(match) {
      return String.fromCharCode(match.charCodeAt(0) - 0xfee0);
    });
  }

  toFullSize(str) {
    // 半角英数字 → 全角英数字
    return str.replace(/[A-Za-z0-9]/g, function(match) {
      return String.fromCharCode(match.charCodeAt(0) + 0xfee0);
    });
  }

  toHalfKana(str) {
    const reg = new RegExp("(" + Object.keys(halfKanaMap).join("|") + ")", "g");
    return str.replace(reg, function(match) {
      return halfKanaMap[match];
    });
  }

  toFullKana(str) {
    const reg = new RegExp("(" + Object.keys(fullKanaMap).join("|") + ")", "g");
    return str.replace(reg, function(match) {
      return fullKanaMap[match];
    });
  }

  checkPattern(caption, str) {
    return (
      caption.indexOf(str) > -1 || caption.indexOf(str.replace(/\s+/g, "")) > -1
    );
  }

  setValidateError(errors) {
    this.setState({
      error: {
        caption: errors.find(elm => elm.indexOf("コレクション名") !== -1) || "",
        description: errors.find(elm => elm.indexOf("説明") !== -1) || "",
        definition: errors.find(elm => elm.indexOf("定義") !== -1) || "",
        background: errors.find(elm => elm.indexOf("背景") !== -1) || "",
        task: errors.find(elm => elm.indexOf("課題") !== -1) || "",
        technology: errors.find(elm => elm.indexOf("技術") !== -1) || "",
        topics: errors.find(elm => elm.indexOf("話題") !== -1) || "",
        regulation: errors.find(elm => elm.indexOf("法規制") !== -1) || "",
        other: errors.filter(elm =>
          [
            "コレクション名",
            "説明",
            "定義",
            "背景",
            "課題",
            "技術",
            "話題",
            "法規制"
          ].every(string => elm.indexOf(string) === -1)
        )
      }
    });
  }

  trackMediaCheck(collectionId, mediaCode, checked, newCollection) {
    trackAction("clickMediaCheckBox", {
      collectionId,
      mediaCode,
      checked,
      newCollection
    });
  }

  render() {
    const list =
      this.state.keyword.length === 0
        ? this.props.list
        : this.props.list.filter(item => {
            // カタカナ → ひらがな
            const hiragana = this.state.keyword.replace(
              /[\u30a1-\u30f6]/g,
              function(match) {
                return String.fromCharCode(match.charCodeAt(0) - 0x60);
              }
            );

            // ひらがな → カタカナ
            const katakana = this.state.keyword.replace(
              /[\u3041-\u3096]/g,
              function(match) {
                return String.fromCharCode(match.charCodeAt(0) + 0x60);
              }
            );

            // 大文字 → 小文字
            const lowerKeyword = this.state.keyword.toLowerCase();

            // 小文字 → 大文字
            const upperKeyword = this.state.keyword.toUpperCase();

            // 全角英数字 → 半角英数字（lower case）
            const lowerHalfSize = this.toHalfSize(lowerKeyword);

            // 全角英数字 → 半角英数字（upper case）
            const upperHalfSize = this.toHalfSize(upperKeyword);

            // 半角英数字 → 全角英数字（lower case）
            const lowerFullSize = this.toFullSize(lowerKeyword);

            // 半角英数字 → 全角英数字（upper case）
            const upperFullSize = this.toFullSize(upperKeyword);

            // 全角カナ、空白 → 半角カナ、空白
            const halfKanaStr = this.toHalfKana(this.state.keyword);

            // 半角カナ、空白 → 全角カナ、空白
            const fullKanaStr = this.toFullKana(this.state.keyword);

            return (
              this.checkPattern(item.caption, this.state.keyword) ||
              this.checkPattern(item.caption, hiragana) ||
              this.checkPattern(item.caption, katakana) ||
              this.checkPattern(item.caption, lowerHalfSize) ||
              this.checkPattern(item.caption, lowerFullSize) ||
              this.checkPattern(item.caption, upperHalfSize) ||
              this.checkPattern(item.caption, upperFullSize) ||
              this.checkPattern(item.caption, halfKanaStr) ||
              this.checkPattern(item.caption, fullKanaStr)
            );
          });

    return (
      <div
        className={cn(styles.component, {
          [styles.small]: this.props.size === "small",
          [styles.iconSize]: this.props.size === "icon"
        })}
        ref={this.wrapperRef}
      >
        {this.renderButton()}
        {this.state.isOpen && (
          <React.Fragment>
            <div
              className={cn(styles.listWrapper, {
                [styles.upper]: this.shouldDisplayUpperPart(),
                [styles.downer]: !this.shouldDisplayUpperPart(),
                [styles.small]: this.props.size === "small"
              })}
            >
              <div className={styles.search}>
                <span className={styles.searchIcon} />
                <span className={styles.inputWrapper}>
                  <input
                    data-testid="Common-BookmarkBalloon-CollectionSearch"
                    ref={this.inputRef}
                    type="text"
                    placeholder="登録先コレクションを絞り込み"
                    onChange={e => this.setState({ keyword: e.target.value })}
                    value={this.state.keyword}
                  />
                </span>
                <span
                  className={styles.icon}
                  onClick={event => {
                    event.stopPropagation();
                    this.setState({ keyword: "" });
                    this.setState(cloneDeep(initialState));
                  }}
                />
              </div>
              {this.props.isLoading ? (
                <div className={styles.loading}>
                  <LoadingSpinner size="L" />
                </div>
              ) : (
                <ul
                  data-testid="Common-BookmarkBalloon-BookmarkInfoList-ul"
                  className={styles.vsList}
                >
                  {list.map((item, index) => (
                    <li
                      data-testid={`Common-BookmarkBalloon-BookmarkInfoList-li-${index}`}
                      key={index}
                      onClick={event => {
                        event.stopPropagation();

                        this.props.insertItem({
                          binderId: item.binderId
                        });
                        if (this.props.trackActionType === "AI") {
                          trackAction("saveBookmarkFromAI", {
                            articleId: this.props.kijiId,
                            articleTitle: this.props.kijiTitle,
                            binderId: item.binderId,
                            binderName: item.caption
                          });
                        }
                        this.setState(cloneDeep(initialState));
                        trackAction("collectionAddtionSave", item.binderId);
                      }}
                    >
                      {item.caption}
                    </li>
                  ))}
                </ul>
              )}
              <div className={styles.bookmark}>
                <div
                  className={styles.createBinder}
                  onClick={event => {
                    event.stopPropagation();
                    this.createBinderClickHandler();
                    this.setDefaultFeedKeywords();
                  }}
                  data-testid="Common-BookmarkBalloon-Implementation-openCreateBinderModal"
                >
                  コレクションを作成して登録
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        <BinderActionModal
          onClose={this.onClose}
          handleSave={this.handleSave}
          isSaveLoading={this.state.isSaveLoading}
          isOpen={this.state.binder.isOpenCollectionModal}
          errors={this.state.error.other}
        />
        <OverseasPremiumModal
          isOpen={this.state.isOpenDetailModal}
          onClose={() => this.setState({ isOpenDetailModal: false })}
        />
      </div>
    );
  }
}
BookMarkBalloon.defaultProps = defaultProps;

const mapStateToProps = state => ({
  binderAction: state.reducers.binders.list.binderAction,
  openFlg: state.reducers.binders.list.openFlg
});

export default connect(mapStateToProps)(modalUtil(toast(BookMarkBalloon)));
