import React from "react";
import styles from "./style.scss";

import { isEmpty } from "utils/helper/common";
import cn from "classnames";

/**
 * セレクトボックス
 *
 * @prop {object[]} options - オプションリスト
 * @prop {?string} label - ラベル
 * @prop {?string} className - ラベル
 * @prop {?boolean} multiple - 複数選択可否
 * @prop {?number} size - 複数行時の行数指定
 * @prop {?boolean} uncontrolled - 状態管理を手動でするかどうか (label のみ)
 *   true の場合は value に、false の場合は defaultValue に selectedOption を与える
 * @prop {?boolean} disabled - 無効状態かどうか
 * options = [
 *  { value: "200709", label: "2007" }, // 通常の項目
 *  { label: "国内, group: [
 *    { value: "01", label: "北海道" } // 小要素 optgroup
 *  ]}
 * ]
 */

interface Options {
  value?: string | number;
  label: string | number;
  displayLabel?: string;
  group?: Options[];
}

interface Props {
  options: Options[];
  label?: string;
  className?: string;
  selectedOption?: string;
  multiple?: boolean;
  size?: number;
  uncontrolled?: boolean;
  disabled?: boolean;
  isNoBorder?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLSelectElement>) => void;
}

const SelectBox: React.FunctionComponent<Props> = (
  {
    options = [],
    label = null,
    className = "",
    selectedOption,
    uncontrolled = false,
    disabled = false,
    isNoBorder = false,
    onChange = () => {},
    onClick = () => {}
  },
  props
) => {
  if (!options) return null;

  const _props = Object.assign({}, props);
  delete _props.selectedOption;

  const selectOptions = (
    <React.Fragment>
      {options.map(option => {
        if (option.group && !isEmpty(option.group)) {
          return (
            <optgroup key={option.label} label={`${option.label}`}>
              {option.group.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label || option.displayLabel}
                </option>
              ))}
            </optgroup>
          );
        }
        return (
          <option key={option.value} value={option.value}>
            {option.label || option.displayLabel}
          </option>
        );
      })}
    </React.Fragment>
  );

  const select = (
    <div
      data-testid="drop-down-select"
      className={cn(className, styles.select, {
        [styles.disabled]: disabled,
        [styles.noBorder]: isNoBorder
      })}
    >
      {uncontrolled ? (
        <select
          onChange={onChange}
          onClick={onClick}
          value={selectedOption}
          disabled={disabled}
          data-testid={`Common-SelectBox-uncontrolled-${selectedOption}`}
        >
          {selectOptions}
        </select>
      ) : (
        <select
          onChange={onChange}
          onClick={onClick}
          defaultValue={selectedOption}
          disabled={disabled}
          data-testid={`Common-SelectBox-controlled-${selectedOption}`}
        >
          {selectOptions}
        </select>
      )}
      <span className={`${styles.icon} ${disabled && styles.disabled}`} />
    </div>
  );

  return (
    <div
      className={styles.component}
      data-testid="components-Common-SelectBox-component"
    >
      {label ? (
        <label className={styles.label}>
          <span>{label}</span>
          {select}
        </label>
      ) : (
        select
      )}
    </div>
  );
};

export default SelectBox;
