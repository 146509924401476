import React, { useEffect, useRef, useState } from "react";
import { Sort } from "../Common/Sort";
import { Card } from "../Common/Card";
import { Table } from "../Common/Table";
import { Empty } from "../../../Common/Empty";
import { SearchEmpty } from "../../../Common/SearchEmpty";
import styles from "./style.scss";
import { useNikkeiBinder } from "./hook";
import Loading from "components/Common/Loading";
import Pager from "components/Common/Pager";
import LoadingImage from "images/icon/loading.svg";
import { useBinderAction } from "../../../Common/BinderActionModal/hook";

const FIRST_PAGE = "1";
const PREV_PAGE = "2";
const NEXT_PAGE = "3";

export const NikkeiBinderList: React.FunctionComponent = () => {
  const {
    isLoading,
    sortValue,
    binderItems,
    isError,
    fetchNikkeiBinders,
    sortNikkeiBinders,
    cardViewMode,
    addNikkeiBinders,
    isAdding,
    changePage,
    changeLimit,
    switchCardViewMode,
    totalCount,
    offset,
    limit,
    onKeywordSearch,
    nikkeiKeyword,
    setNikkeiKeyword,
    isSearch
  } = useNikkeiBinder();

  const tableWrapperRef = useRef(null);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(limit);
  const { setBinderActionModal } = useBinderAction();
  const [bottom, setBottom] = useState(false);

  const onClickPage = (pageNum: number, flag?: string) => {
    let sendOffset;
    switch (flag) {
      case FIRST_PAGE: {
        sendOffset = 0;
        break;
      }
      case PREV_PAGE: {
        sendOffset = (pageNum - 2) * limit;
        break;
      }
      case NEXT_PAGE: {
        sendOffset = pageNum * limit;
        break;
      }
      default: {
        sendOffset = (pageNum - 1) * limit;
        break;
      }
    }

    changePage(sendOffset, limit);
  };

  useEffect(() => {
    setPageStart(1);
    setPageEnd(limit);
  }, [setPageStart, setPageEnd, limit]);

  useEffect(() => {
    if (cardViewMode) {
      if (bottom && !isLoading && !isAdding) {
        setBottom(false);
        addNikkeiBinders();
      }
      const onScroll = () => {
        const isBottom =
          window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.offsetHeight;
        setBottom(isBottom);
      };

      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [addNikkeiBinders, bottom, cardViewMode, isAdding, isLoading]);

  useEffect(() => {
    fetchNikkeiBinders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageStart(offset + 1);
    if (offset + limit < totalCount) {
      setPageEnd(offset + limit);
    } else {
      setPageEnd(totalCount);
    }
  }, [offset, limit, totalCount]);

  // エラーの場合、何も表示しない
  if (isError) return null;

  if (isLoading && binderItems.length === 0 && !isSearch) {
    return (
      <div className={styles.component}>
        <div className={styles.loadingWrapper}>
          <Loading isLoading={isLoading} />
        </div>
      </div>
    );
  }

  if (isSearch && binderItems.length === 0 && !isLoading) {
    return (
      <div className={styles.component}>
        <div className={styles.sortWrapper}>
          <Sort
            onChange={sortNikkeiBinders}
            setCardViewMode={switchCardViewMode}
            cardViewMode={cardViewMode}
            changeLimit={changeLimit}
            limit={limit}
            sort={sortValue}
            onKeywordSearch={onKeywordSearch}
            keyword={nikkeiKeyword}
            setKeyword={setNikkeiKeyword}
            isSearch={isSearch}
          />
        </div>
        <div
          className={styles.collectionWrapper}
          data-testid="BinderPage-List-Components-NikkeiBinderList-collectionWrapper"
        >
          <div
            className={cardViewMode ? styles.card : styles.list}
            ref={tableWrapperRef}
          >
            <SearchEmpty />
          </div>
        </div>
      </div>
    );
  }

  if (!isLoading && binderItems.length === 0) {
    return (
      <div className={styles.component}>
        <Empty
          name="nikkeiBinder"
          subLabel="コレクションが追加されるまでお待ちください"
        />
      </div>
    );
  }

  return (
    <div className={styles.component}>
      <div className={styles.sortWrapper}>
        <Sort
          onChange={sortNikkeiBinders}
          setCardViewMode={switchCardViewMode}
          cardViewMode={cardViewMode}
          changeLimit={changeLimit}
          limit={limit}
          sort={sortValue}
          onKeywordSearch={onKeywordSearch}
          keyword={nikkeiKeyword}
          setKeyword={setNikkeiKeyword}
          isSearch={isSearch}
        />
      </div>
      <div
        className={styles.collectionWrapper}
        data-testid="BinderPage-List-Components-NikkeiBinderList-collectionWrapper"
      >
        <div
          className={`${cardViewMode ? styles.card : styles.list} ${isLoading &&
            styles.overflowHidden}`}
          ref={tableWrapperRef}
        >
          {isLoading && sortValue !== "" && (
            <div className={styles.component}>
              <div className={styles.loadingWrapper}>
                <Loading isLoading={isLoading} />
              </div>
            </div>
          )}
          {cardViewMode
            ? binderItems.map((binderItem, index) => {
                return (
                  <Card
                    key={index}
                    type="nikkei"
                    binderItem={binderItem}
                    setBinderActionModal={setBinderActionModal}
                  />
                );
              })
            : binderItems.length !== 0 && (
                <div
                  className={`${styles.full} ${isLoading && styles.absolute}`}
                >
                  <Table
                    items={binderItems}
                    type="nikkei"
                    setBinderActionModal={setBinderActionModal}
                    tableWrapperRef={tableWrapperRef}
                  />
                  <Pager
                    page={offset / limit + 1}
                    perPage={limit}
                    totalNumber={totalCount}
                    onClick={onClickPage}
                    start={pageStart.toString()}
                    end={pageEnd.toString()}
                  />
                </div>
              )}
        </div>
        {isAdding && (
          <div className={styles.loadingContainer}>
            <img
              src={LoadingImage}
              alt="読み込み中"
              className={styles.loading}
            />
          </div>
        )}
      </div>
    </div>
  );
};
