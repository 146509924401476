import { SimpleIndustry } from "./actionType";
import { Reducer } from "redux";
import produce from "immer";
import { FileDownload } from "models/FileDownload";
import {
  IndustrySearchResult,
  BinderResultList,
  ReportResult,
  IndustryFilter,
  NeedsIndustry,
  AllSelectResultResponse
} from "models/SimpleSearch";

interface SearchTargetDataList {
  label: string;
  selected: boolean;
  value: string;
}

export interface SimpleIndustryState {
  reportKeyword: string;
  sampleFilePath: FileDownload;
  searchTargetDataList: SearchTargetDataList[];
  selected: IndustryFilter;
  industrySearchResult: IndustrySearchResult;
  reportResult: ReportResult;
  binderResultList: BinderResultList;
  needsIndustry: NeedsIndustry;
  allSelectResult: AllSelectResultResponse;
}

const initialState: SimpleIndustryState = {
  reportKeyword: "",
  sampleFilePath: {
    dlcsv: "",
    dlpropriety: "",
    dlcsvpath: "",
    dlxls: "",
    dlxlspath: ""
  },
  selected: {
    perPage: "50",
    sort: "0",
    source: ["1", "2", "3", "4", "5"]
  },
  searchTargetDataList: [],
  allSelectResult: {
    industryCodes: [],
    size: 0
  },
  industrySearchResult: {
    body: "",
    characterCount: "",
    currentPage: "",
    checkedIndustryCodes: "",
    endNum: "",
    endPageFlag: "",
    errorFlg: false,
    headline: "",
    individualName1: "",
    individualNikkeiCode1: "",
    individualSecCode1: "",
    industryInfo: [
      {
        sectorReportPath: "",
        keyPpt: "",
        industryNameL: "",
        industryNameM: "",
        industryNameS: "",
        industryCodeL: "",
        industryCodeM: "",
        industryCodeS: "",
        definition: [""],
        definitionHighlight: [""],
        outlineFullText: [""],
        outlineHighligtFullText: [""],
        outline: [""],
        outlineHighlight: [""],
        marketTrendFullText: [""],
        marketTrendHighlightFullText: [""],
        marketTrend: [""],
        marketTrendHighlight: [""],
        competitiveSituationFullText: [""],
        competitiveSituationHighlightFullText: [""],
        competitiveSituation: [""],
        competitiveSituationHighlight: [""],
        pptDownloadInfo: {
          type: "",
          fileType: "",
          fileName: "",
          title: ""
        }
      }
    ],
    keyBinaly: "",
    keyPdf: "",
    keyText: "",
    kigyouName: "",
    mediaName: "",
    messageDto: { message: "", msgCode: "" },
    messages: [""],
    pageFromTo: "",
    placeMarket: "",
    printedDate: "",
    query: "",
    shareholderName: "",
    shareholderNikkeiCode: "",
    startNum: "",
    startPageFlag: "",
    stkcode: "",
    totalCount: "",
    transitionId: ""
  },
  reportResult: {
    accessContentId: "",
    backFlag: "",
    baitaiCheck: "",
    baitaiCheckNameList: [""],
    corpIndustyKbn: "",
    count: "",
    countryListFlg: "",
    fld: "",
    fldNameList: [""],
    isConditions: "",
    keyword: "",
    kijiTermName: "",
    kikan: "",
    notKeyword: "",
    orKeyword: "",
    query: "",
    reportResearchRankingListDto: {
      body: "",
      characterCount: "",
      currentPage: "",
      endNum: "",
      endPageFlag: "",
      errorFlg: false,
      excelDownLoadInfo: [""],
      headline: "",
      individualName1: "",
      individualNikkeiCode1: "",
      individualSecCode1: "",
      keyBinaly: "",
      keyPdf: "",
      keyPpt: "",
      keyText: "",
      kigyouName: "",
      mediaName: "",
      messageDto: { msgCode: "", message: "" },
      messages: [""],
      newsDatas: [
        {
          articleKbn: "",
          characterCount: "",
          companyInfoList: [
            {
              asrf: "",
              corpIndustyKbn: "",
              corpName: "",
              corpType: "",
              diffflg: "",
              nikkeiCode: "",
              stkcode: ""
            }
          ],
          headline: "",
          keyBinary: "",
          keyBody: "",
          keyBodyFare: "",
          keyPdf: "",
          keyPdfFare: "",
          keyPpt: "",
          keyText: "",
          linkFlg: "",
          mediaCode: "",
          mediaFeeType: "",
          mediaName: "",
          pageFromTo: "",
          pdfFlg: "",
          pdfImg: "",
          picture: "",
          printedDate: "",
          xbrlImg: ""
        }
      ],
      pageFromTo: "",
      pdfDownLoadInfo: [""],
      placeMarket: "",
      printedDate: "",
      query: "",
      searchReportKey: "",
      shareholderName: "",
      shareholderNikkeiCode: "",
      sort: "",
      startNum: "",
      startPageFlag: "",
      stkcode: "",
      tldTransitionId: "",
      totalCount: 0,
      transitionId: "",
      xbrlDownLoadInfo: [""]
    },
    sameInfoIsNotFound: false,
    searchModeType: "",
    selectedScreenId: "",
    subContextPath: "",
    tokenKey: "",
    updateDateFrom: "",
    updateDateTo: ""
  },
  binderResultList: {
    binderResultList: [],
    count: { total: "", personal: "", nikkei: "" },
    currentPage: ""
  },
  needsIndustry: {
    value: "",
    needsName: ""
  }
};

export const simpleIndustry: Reducer<SimpleIndustryState, SimpleIndustry> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "industry-search/SET_KEYWORD":
      return produce(state, draft => {
        draft.reportKeyword = action.payload;
      });

    case "industry-search/SET_FILE_PATH":
      return produce(state, draft => {
        draft.sampleFilePath = action.payload;
      });
    case "simple-industry/INDUSTRY_SEARCHLIST_FILTER_GET":
      return produce(state, draft => {
        draft.searchTargetDataList = action.payload;
      });
    case "industry-search/search/SEARCH_INDUSTRY":
      return produce(state, draft => {
        draft.industrySearchResult = action.payload;
      });
    case "industry-search/search/SEARCH_REPORT":
      return produce(state, draft => {
        draft.reportResult = action.payload;
      });

    case "industry-search/binder-nikkei/SEARCH_BINDER_INDUSTRY":
      return produce(state, draft => {
        draft.binderResultList = action.payload;
      });

    case "industry-search/search/UPDATE_INDUSTRY_FILTER":
      return produce(state, draft => {
        draft.selected = action.payload;
      });
    case "industry-search/search/SET_FILTER_CONDITION_VALUE":
      return produce(state, draft => {
        draft.needsIndustry = action.payload;
      });
    case "industry-search/search/UPDATE_CHECKBOX":
      return produce(state, draft => {
        draft.industrySearchResult.checkedIndustryCodes = action.payload;
      });
    case "industry-search/search/INDUSTRY_SIMPLE_SEARCH_All_GET":
      return produce(state, draft => {
        draft.allSelectResult = action.payload;
      });
    case "industry-search/search/CHECKED_INDUSTRY_CODES_INIT":
      return produce(state, draft => {
        draft.industrySearchResult.checkedIndustryCodes = action.payload;
      });

    default:
      return state;
  }
};
