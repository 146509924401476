import React, { ReactNode } from "react";
import styles from "./style.scss";
import cn from "classnames";

interface Props {
  level: number | string;
  className?: string;
  underline?: boolean;
  id?: string;
  children: ReactNode;
}

/**
 * ヘッダー
 * @param {string} level - ヘッダーレベル
 * @param {string} className - クラス名
 * @param {boolean} underline - 下線つけるか
 * @param {object} children - 子要素
 */
const Heading: React.FunctionComponent<Props> = ({
  level = 3,
  className = "",
  underline = false,
  id,
  children
}) => {
  return React.createElement(
    `h${level}`,
    {
      className: cn(styles.base, styles[`heading${level}`], className, {
        [styles.underline]: underline
      }),
      "data-testid": "src-components-Common-Heading-base",
      id: id
    },
    children
  );
};

export default Heading;
