import { request } from "utils/apiClient/base";
import { isEmpty, valueFromKeyOrDefault } from "utils/helper/common";
import dlUrl from "utils/json/downloadUrl";
import { setPdfParams } from "./localStorage";
import { cloneDeep } from "lodash";
import { store } from "stores/store";
import { trackAction } from "utils/helper/analytics";

/**
 * download
 * 渡されたファイル情報をもとに API をリクエストし、ファイルをダウンロードする
 * @param {object} file ファイル情報
 * file: {
 *   params: {}, // ファイルデータ取得 API リクエストパラメータ
 *   key: "xls", // downloadUrl.json に記載した API を特定する第二キー
 *   getSvgData: null, // svgData が必要な場合に取得するためのコールバック関数
 *   svgInfos: [] // svgData のx番目をどのパラメータ名で適用するかのルール
 *   generateSvgParams: null // svgData をパラメータに変換するコールバック関数、複雑なルールがある場合のみ使用
 * }
 * @param {?string} pathname downloadUrl.jsonに記載したAPI情報を特定する第1キー、特定の画面に依存しない時に使う
 */

export async function download(
  file = {},
  pathname = location.pathname,
  argParams = {},
  type = "",
  fileType = "",
  fileName = "",
  title = ""
) {
  const downloadFile = cloneDeep(file);
  const { key } = downloadFile;
  if (isEmpty(key)) return;

  let { params } = downloadFile;
  if (!isEmpty(downloadFile.getSvgData)) {
    const svgData = await downloadFile.getSvgData(params);
    // 特殊なパラメータルールがある場合は、コールバック関数にてパラメータ生成する
    if (!isEmpty(downloadFile.generateSvgParams)) {
      // eslint-disable-next-line require-atomic-updates
      params = { ...params, ...downloadFile.generateSvgParams(svgData) };
    } else {
      // 与えられたルールで付与する
      downloadFile.svgInfos.forEach(
        info => (params[info.key] = svgData[info.index] || "")
      );
    }
  }

  // ダウンロードURLの情報取得
  if (pathname.startsWith("/corp/finance")) {
    pathname = "/corp/finance";
  }

  let urlNumber = dlUrl[pathname][key];
  if (!urlNumber && key === "freePdf") {
    urlNumber = dlUrl[pathname]["pdf"];
  }

  // PDF の場合
  if (key.toLowerCase().indexOf("pdf") !== -1) {
    store.dispatch({
      type: "auth/saved/SET_REDIRECT_PATH",
      payload: `${location.pathname}${location.search}`
    });
    await downloadPdf(urlNumber, params, argParams.viewUrl, argParams.keywords);
    if (argParams.fileType === "業界分析レポート") {
      trackAction("downloadVsIndustryAnalysisReportComplete", {
        type,
        fileType,
        fileName,
        title
      });
    } else {
      trackAction("downloadFile", {
        type: "PDF"
      });
    }
    return;
  }

  // 非同期ダウンロードの場合
  if (
    file.params.downloadCheckFlg === "2" ||
    file.params.downloadCheckFlg === "3" ||
    file.params.downloadCheckFlg === "4"
  ) {
    return downloadAsynchronous(urlNumber, params);
  }

  // ダウンロード
  try {
    const downloadUrl = await getDownloadUrl(urlNumber, params, pathname);
    // eslint-disable-next-line require-atomic-updates
    location.href = downloadUrl;
    // 日経業界分析レポート（PPT）
    if (argParams.fileType === "業界分析レポート") {
      trackAction("downloadVsIndustryAnalysisReportComplete", {
        type,
        fileType,
        fileName,
        title
      });
    } else {
      trackAction("downloadFile", {
        type: downloadFile.type ? downloadFile.type.toUpperCase() : "",
        state:
          pathname === "/tool/screening/corp" && argParams.state
            ? argParams.state
            : undefined, // TODO: 企業リスト作成の旧UIが廃止されたら state を消す,
        ...(argParams.fileType && { fileType: argParams.fileType })
      });
    }
  } catch (error) {
    const message = valueFromKeyOrDefault(error.body, "details.0.message", "");
    const newError = new Error(message);
    // エラーコードを含める
    newError.body = error.message;
    return Promise.reject(newError);
  }
}

/**
 * PDFダウンロード
 * @param {string} urlNumber API 番号
 * @param {object} params リクエストパラメーター
 */
async function downloadPdf(urlNumber, params, url, keywords) {
  // ポップアップブロック対策として新しいタブを開いてから処理
  setPdfParams(urlNumber, params, keywords);
  window.open(url || "/pdf");
}

/**
 * ダウンロードURL取得
 * @param {string} urlNumber API 番号
 * @param {object} params リクエストパラメーター
 * @param {?string} pathname downloadUrl.jsonに記載したAPI情報を特定する第1キー、特定の画面に依存しない時に使う
 * @returns {string} ダウンロードURL
 */
export async function getDownloadUrl(urlNumber, params, pathname) {
  try {
    const res = await request(urlNumber, params);

    let host = location.hostname;
    if (host === "localhost") host = API_DOMAIN;

    if (typeof res === "string") {
      // 通常ダウンロード
      return `https://${host}${res}`;
    } else if (typeof res === "object") {
      // ファイルダウンロード追加リクエスト
      const dlParams = {
        corpPlaceMarket: params.corpPlaceMarket,
        corpStkCode: params.corpStkCode,
        corpIndustyCode: params.corpIndustyCode,
        corpType: params.corpType,
        corpIndustyKbn: params.corpIndustyKbn,
        filePath: res.filePath
      };
      const dlUrlNumber = dlUrl[pathname]["file"];
      const dlRes = await request(dlUrlNumber, dlParams);
      return `https://${host}${dlRes}`;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export function generateResourceId() {
  // 乱数で整数値を返す
  return `id_${Math.floor(Math.random() * 100000000)}`;
}

/**
 * 非同期ダウンロード
 * @param {string} urlNumber API 番号
 * @param {object} params リクエストパラメーター
 */
async function downloadAsynchronous(urlNumber, params) {
  try {
    return await request(urlNumber, params);
  } catch (error) {
    return Promise.reject(error);
  }
}
