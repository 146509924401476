import React, { useEffect, useState } from "react";
import styles from "./style.scss";

import { isEmpty } from "utils/helper/common";

interface Option {
  seq: string;
  label: string;
  inneyCode: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  optionList: Option[];
  className?: string;
  isBold?: boolean;
  onSelected: (option: Option) => void;
  selectedOption: string;
}

/**
 * 汎用Select要素
 *
 * @prop {object[]} optionList - option項目の配列
 * @prop {string} selectedOption - 選択中のoption項目の文字列
 * @prop {function} onSelected - 選択時の要素を返す callback
 * @prop {?boolean} isBold - 太字にするか
 * options = [
 *  {value: "200709", label: "2007"}
 * ]
 */

const SelectRadioButton: React.FunctionComponent<Props> = ({
  optionList = [],
  selectedOption = "",
  onSelected = () => {},
  className,
  isBold
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let index = 0;
    if (!isEmpty(optionList) && selectedOption) {
      index = optionList.findIndex(option => option.value == selectedOption);
      if (index < 0) index = 0;
    }

    setSelectedIndex(index);
  }, [optionList, selectedOption]);

  const handleClick = (index: number) => {
    if (optionList[index].disabled) return;
    setSelectedIndex(index);
    onSelected(optionList[index]);
  };

  if (isEmpty(optionList)) return null;

  return (
    <ul
      className={`${styles.component} ${className}`}
      data-testid="radiobutton-button"
    >
      {optionList.map((option, index) => {
        const isActive = index == selectedIndex;
        return (
          <li
            key={index}
            className={`
                ${isActive && styles.active}
                ${isBold && styles.bold}
                ${option.disabled && styles.disabled}
              `}
            onClick={() => handleClick(index)}
            data-testid={`radiobutton-button_button-${index}-${
              isActive ? "active" : "inactive"
            }`}
          >
            {option.label}
          </li>
        );
      })}
    </ul>
  );
};

export default SelectRadioButton;
