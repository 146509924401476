import React from "react";
import styles from "./style.scss";
import cn from "classnames";

/**
 * チェックボックス
 *
 * @prop {string} id - ID
 * @prop {string} name - ラベル名
 * @prop {?string} className - クラス名
 * @prop {?function} onChange - 変更時処理
 * @prop {?boolean} isActive - 活性ならtrue、非活性ならfalse
 * @prop {?boolean} isChecked - 選択状態ならtrue、非選択状態ならfalse
 * @prop {?boolean} isIndeterminate - 中間チェック状態ならtrue、そうでないならfalse
 * @prop {?boolean} isSmall - trueなら小さいチェックボックス
 * @prop {?number} count - 件数。nullなら件数表示しない
 * @prop {?boolean} hasBorder - 枠線のありなし
 */

interface Props {
  id?: string;
  className?: string;
  isLarge?: boolean;
  isActive?: boolean;
  isChecked: boolean;
  isIndeterminate?: boolean;
  isSmall?: boolean;
  count?: number;
  hasBorder?: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  textClassName?: string;
  noHover?: boolean;
  checkboxPos?: boolean;
}

const Checkbox: React.FunctionComponent<Props> = ({
  id,
  className = "",
  isLarge = false,
  isActive = true,
  isChecked = false,
  isIndeterminate = false,
  isSmall = false,
  count = null,
  hasBorder = false,
  name = null,
  onChange = () => {},
  onFocus = () => {},
  textClassName = "",
  noHover = false,
  checkboxPos
}) => {
  const minSize = isSmall ? "13px" : "17px";
  const checkBoxWidth = {
    minWidth: minSize
  };

  // Labelに付与したクリックイベント
  // SPAN（Label）が発火元の場合は上位にイベント伝播させない(INPUTのclickイベントが上がる)
  const onClick = (e: React.MouseEvent) => {
    const unknownTarget: unknown = e.target;
    const target = unknownTarget as HTMLElement;
    if (target.tagName === "SPAN") {
      e.stopPropagation();
    }
  };

  // 中間チェック状態かどうか
  const isCheckboxIndeterminate = isIndeterminate;
  // また、中間チェック状態の場合、チェック済でなく未チェックとして扱う
  // (チェック済 / 未チェックでデザインが異なるため、どっち付かずだと共通化できない)
  const isCheckboxChecked = isCheckboxIndeterminate ? false : isChecked;

  return (
    <label
      data-testid="checkbox_label"
      key={id}
      className={cn(styles.label, className, {
        [styles.border]: hasBorder,
        [styles.disabled]: !isActive
      })}
      style={checkBoxWidth}
      onClick={onClick}
    >
      <input
        type="checkbox"
        id={id}
        checked={isCheckboxChecked}
        onChange={onChange}
        onFocus={onFocus}
        className={styles.checkbox}
        disabled={!isActive}
        data-testid="checkbox"
      />
      <span
        className={cn(styles.textWrapper, {
          [styles.noHover]: noHover,
          [styles.large]: isLarge
        })}
      >
        <span
          className={cn(styles.icon, {
            [styles.iconPos]: checkboxPos,
            [styles.small]: isSmall,
            [styles.noMargin]: name === null,
            [styles.indeterminate]: isCheckboxIndeterminate
          })}
          data-testid={`components-Common-Checkbox-${
            isCheckboxIndeterminate ? "indeterminate" : "determinate"
          }`}
        />
        <span className={cn(styles.text, textClassName)}>{name}</span>
      </span>
      {count !== null && <span className={styles.count}>{count}</span>}
    </label>
  );
};

export default Checkbox;
