import React from "react";
import styles from "./style.scss";

import sectionLoading from "./enhance";

const Loading = ({ children = null }) => {
  return (
    <div
      className={styles.component}
      data-testid="src-components-Common-SectionLoading-component"
    >
      {children}
    </div>
  );
};

export default sectionLoading(Loading);
